<template>
  <div class="marketing-container">
    <el-form :inline="true" size="small" class="search-box" :model="searchForm">
      <el-form-item>
        <el-button type="primary" @click="addSupplier">添加运营商</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }"
      :data="list"
      style="width: 100%"
    >
       <el-table-column
        align="center"
        prop="name"
        label="供应商名称"
        min-width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="sender_name"
        label="发件人姓名"
        min-width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="sender_mobile"
        label="发件人手机号"
        min-width="100"
        show-overflow-tooltip
      >
      </el-table-column>
       <el-table-column
        align="center"
        label="发件人地址"
        prop="address"
        min-width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        align="center"
        label="备注"
        min-width="200"
        show-overflow-tooltip
        prop="remark"
      ></el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" style="color: #f56c6c" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
        class="pagination"
        background
        layout="total, slot, prev, pager, next"
        :current-page="searchForm.pageNum"
        :page-size="searchForm.pageSize"
        :total="searchForm.total"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <Edit ref="edit" @refresh="getSupplierList" />
  </div>
</template>

<script>
import { fetchSupplierList, fetchUpdateSupplier } from "@/api/marketing";
import Edit from "./components/supplierEdit.vue";

export default {
  name: "supplierMarketing",
  data() {
    return {
      isLoading: false,
      list: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  filters: {
    date(t) {
      return t ? new Date(t * 1000).toLocaleString() : "-";
    },
  },
  components: {
    Edit,
  },
  created() {
    this.getSupplierList();
  },
  methods: {
    getSupplierList() {
      const data = {
        index: this.searchForm.pageNum - 1,
        size: this.searchForm.pageSize,
      };
      fetchSupplierList(data)
        .then((res) => {
          const list = res.data.list || [];
          list.forEach((item) => {
            const {province, city, county, address} = item.sender_address
            item.address = `${province}/${city}/${county}/${address}`
          });
          this.searchForm.total = res.data.total;
          this.list = list;
        })
        .catch(() => {});
    },
    // 页码变更
    handlePageChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      this.getSupplierList();
    },
    addSupplier() {
      this.$refs.edit.open({ type: "add" });
    },
    edit(supplier) {
      this.$refs.edit.open({ type: "mod", supplier });
    },
    del(supplier) {
      this.$confirm(`确认要删除该运营商吗？`, "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = {
            supplier_id: supplier.supplier_id,
            edit_type: "del",
          };

          fetchUpdateSupplier(data)
            .then(() => {
              this.$message.success("操作成功");
              this.getSupplierList();
            })
            .catch(() => {});
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.marketing-container {
  background: #fff;
  padding: 30px;

  .search-box {
    margin-bottom: 20px;

    .el-form-item {
      margin-right: 20px;
    }

    .cascader-behind-item {
      margin-right: 10px;
    }

    .el-input-number {
      .el-input {
        width: 100%;
      }
    }

    .el-input {
      width: 200px;
    }

    .span-margin {
      margin: 0 5px;
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .pagination ::v-deep {
      margin-top: 20px;
      text-align: right;
      .el-input__inner {
        text-align: center;
      }
    }
  }
}
</style>
