import Layout from '@/layouts/index.vue'
import Login from '@/views/login/index.vue'
import UpdatePassword from '@/views/updatePassword/index.vue'
import User from '@/views/account/user/index.vue'
import UserList from '@/views/account/list/index.vue'
import Member from '@/views/member/list/index.vue'
import Home from '@/views/home/index.vue'
import GoodsList from '@/views/goods/list/index.vue'
import GoodsGroup from '@/views/goods/group/index.vue'
import GoodsCategory from '@/views/goods/category/index.vue'
import Setting from '@/views/setting/index.vue'
import GoodsMarketing from '@/views/marketing/index.vue'
import SupplierMarketing from '@/views/marketing/supplier.vue'
import Order from '@/views/order/index.vue'

export const defaultRouter = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: Login,
  },
  {
    path: '/updatePassword',
    name: 'updatePassword',
    meta: {
      title: '修改密码',
    },
    component: UpdatePassword,
  },
  {
    path: '*',
    component: Layout,
  },
]

export const routerList = [
  {
    attrAlias: { icon: 'sealqmy-sy', url: '/home' },
    id: 100,
    items: [],
    name: '首页',
    component: Home,
  },
  {
    attrAlias: { icon: 'sealhuiyuanshezhi', url: '/account' },
    id: 101,
    items: [
      {
        attrAlias: { icon: 'sealshouye', url: '/account/user' },
        id: 102,
        items: [],
        name: '个人信息',
        component: User,
      },
      {
        attrAlias: { icon: 'sealshouye', url: '/account/list' },
        id: 103,
        items: [],
        name: '人员列表',
        component: UserList,
        auth: ['admin']
      },
    ],
    name: '账户',
  },
  {
    attrAlias: { icon: 'sealqmy-yhzx', url: '/member' },
    id: 104,
    items: [],
    name: '用户',
    component: Member,
  },
  {
    attrAlias: { icon: 'sealqmy-spzx', url: '/goods' },
    id: 105,
    items: [
      {
        attrAlias: { icon: 'sealshouye', url: '/goods/list' },
        id: 106,
        items: [],
        name: '商品列表',
        component: GoodsList,
      },
      {
        attrAlias: { icon: 'sealshouye', url: '/goods/group' },
        id: 107,
        items: [],
        name: '商品组合',
        component: GoodsGroup,
        auth: ['operation']
      },
      {
        attrAlias: { icon: 'sealshouye', url: '/goods/category' },
        id: 110,
        items: [],
        name: '商品分类',
        component: GoodsCategory,
        auth: ['operation']
      },
    ],
    name: '商品',
  },
  {
    attrAlias: { icon: 'sealqmy-yxzx', url: '/marketing' },
    id: 108,
    items: [
      {
        attrAlias: { icon: 'sealshouye', url: '/marketing/goods' },
        id: 1081,
        items: [],
        name: '商品运营',
        component: GoodsMarketing,
      },
      {
        attrAlias: { icon: 'sealshouye', url: '/marketing/supplier' },
        id: 1082,
        items: [],
        name: '供应商',
        component: SupplierMarketing,
      },
    ],
    name: '运营',
  },
  {
    attrAlias: { icon: 'sealqmy-ddzx', url: '/order' },
    id: 109,
    items: [],
    name: '订单',
    component: Order,
  },
  {
    attrAlias: { icon: 'sealsypos-possz', url: '/setting' },
    id: 200,
    items: [],
    name: '设置',
    component: Setting,
  },
]
