<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="500px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="close"
    class="global-org-vue--new-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      class="global-org-vue--new-form"
      size="small"
    >
      <el-form-item label="供应商名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="发件人姓名" prop="sender_name">
        <el-input v-model="form.sender_name" />
      </el-form-item>
      <el-form-item label="发件人手机号" prop="sender_mobile">
        <el-input v-model="form.sender_mobile" />
      </el-form-item>
      <el-form-item label="省" prop="province">
        <el-input v-model="form.province" />
      </el-form-item>
      <el-form-item label="市" prop="city">
        <el-input v-model="form.city" />
      </el-form-item>
      <el-form-item label="区" prop="county">
        <el-input v-model="form.county" />
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address" />
      </el-form-item>
       <!-- <el-cascader
          ref="area"
          v-model="areaIdsArr"
          :style="`width: 100%`"
          :options="areaList"
          :props="{ expandTrigger: 'hover' }"
          size="small"
          clearable
          :filterable="areaIdsArr.length ? false : true"
          collapse-tags
          class="item-province"
          @change="getDistrict"
        ></el-cascader> -->
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { fetchUpdateSupplier } from "@/api/marketing";

const validatorAddress = (rule, value, callback) => {
  console.log(value, 90)
  // 如果为空值，就抛出错误
  if (!value) {
    // callback(new Error('请输入有效的电话号码'))
  } else {
    const valid = value.province && value.city && value.county && value.address
    if (!valid) {
      callback(new Error('请输入发件人地址'))
    } else {
      callback()
    }
  }
}

export default {
  name: "EditUser",
  data() {
    return {
      dialogVisible: false,
      // areaIdsArr: [],
      form: {
        name: "", // 供应商名称
        supplier_id: "", // 供应商ID
        sender_name: "", // 发件人姓名
        sender_mobile: "", // 发件人手机号
        province: '', // 省
        city: '', // 市
        county: '', // 县区
        address: '', // 地址
        remark: "", // 备注
      },
      rules: {
        sender_address: [{ validator: validatorAddress, trigger: 'blur' }],
        name: [{ required: true, message: "请输入运营商名称", trigger: "blur" }],
        sender_name: [{ required: true, message: "请输入发件人姓名", trigger: "blur" }],
        sender_mobile: [{ required: true, message: "请输入发件人手机号", trigger: "blur" }],
        province: [{ required: true, message: "请输入地址信息", trigger: "blur" }],
        city: [{ required: true, message: "请输入地址信息", trigger: "blur" }],
        county: [{ required: true, message: "请输入地址信息", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址信息", trigger: "blur" }],
      },
      loading: false,
    };
  },
  computed: {
    title() {
      return this.type == "add" ? "添加供应商" : "修改供应商";
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime();
        },
      };
    },
  },
  methods: {
    // 打开弹窗
    open({ type, supplier }) {
      this.type = type;
      if (type == "add") {
        this.form = {
          name: "", // 供应商名称
          supplier_id: "", // 供应商ID
          sender_name: "", // 发件人姓名
          sender_mobile: "", // 发件人手机号
          province: '', // 省
          city: '', // 市
          county: '', // 县区
          address: '', // 地址
          remark: "", // 备注
        };
      } else {
        this.form = {
          name: supplier.name, // 供应商名称
          supplier_id: supplier.supplier_id, // 供应商ID
          sender_name: supplier.sender_name, // 发件人姓名
          sender_mobile: supplier.sender_mobile, // 发件人手机号
          province: supplier.sender_address.province, // 省
          city: supplier.sender_address.city, // 市
          county: supplier.sender_address.county, // 县区
          address: supplier.sender_address.address, // 地址
          remark: supplier.remark || '', // 备注
        };
      }
      this.dialogVisible = true;
    },
    // 关闭
    close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    getDistrict(areaIdsArr) {
      this.areaIdsArr = areaIdsArr
    },
    // 提交
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            edit_type: this.type,
            name: this.form.name, // 供应商名称
            sender_name: this.form.sender_name, // 发件人姓名
            sender_mobile: this.form.sender_mobile, // 发件人手机号
            sender_address: {
              province: this.form.province, // 省
              city: this.form.city, // 市
              county: this.form.county, // 县区
              address: this.form.address, // 地址
            },
            remark: this.form.remark || '', // 备注
          };
          if (this.type == "mod") {
            params.supplier_id = this.form.supplier_id;
          }
          this.loading = true;
          fetchUpdateSupplier(params)
            .then(() => {
              this.loading = false;
              this.$message.success("操作成功");
              this.$emit("refresh");
              this.close();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-radio-button__inner,
  .el-radio-group {
    font-size: 16px !important;
    line-height: 2;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  //兼容火狐
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .input-wrapper .el-input__inner {
    min-width: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.input-wrapper {
  position: relative;
  display: flex;

  .append {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    padding: 0 20px;
    color: #909399;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #f5f7fa;
    border: 1px solid #d9d9d9;
    border-left: 0;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-sizing: border-box;
    line-height: 30px;
  }
}
</style>
